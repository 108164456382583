import React, { Fragment, memo, useEffect, useMemo, useState } from "react";
import { EuiSpacer } from "@elastic/eui";
import { useRouter } from "utils/useRouter";
import { useSelector } from "react-redux";
import { Horizontal } from "components/Lines";
import { PrimaryButton } from "components/Buttons";
import { appVersion } from "utils/appVerison";
import { ContentLoading } from "components/Loadings";
import { bankTypes } from "store/park/model/bankTypes";
import TochkaDepositModal from "../Modals/Modal/TochkaDepositModal";

import {
  StyledSideBarWrapper,
  StyledSideBarHeader,
  StyledSideBarHeaderTitle,
  StyledSideBarAmountBlock,
  StyledSideBarHeaderSubtitle,
  StyledSideBarHeaderButtons,
  StyledSideBarEmail,
  StyledSideBarTopNav,
  StyledSideBarBottomNav,
  StyledSideBarNavGroup,
  StyledSideBarMenuNavList,
  StyledSideBarCents,
  StyledSideBarTopSection,
  StyledSideBarBottomSection,
  StyledSideBarVersion,
} from "./styles";
import { useNavList } from "components/SideMenu/useNavList";
import { useSideBar } from "components/SideMenu/model/context";
import { NavList } from "components/SideMenu/components/NavList";
import { selectParkDataForSideBar } from "components/SideMenu/model/selectors";

const SideMenu = () => {
  const { setOpenedMenu } = useSideBar();
  const { topNavList, bottomNavList } = useNavList();
  const router = useRouter();
  const {
    parkDetailsLoading,
    parkCredentialsLoading,

    bankType,
    subscriptionStatus,
    depositBalance,

    balanceAmount,
  } = useSelector(selectParkDataForSideBar);
  const user = useSelector((state) => state.user);

  const [isTopUpModalOpen, setIsTopUpModalOpen] = useState(false);

  const parkFetchLoading = parkCredentialsLoading || parkDetailsLoading;
  const userEmail = user.profile?.email;
  const userName = user.profile?.full_name;

  const isTochkaBankType = useMemo(() => bankType === bankTypes["tochka"], [
    bankType,
  ]);
  const fontSize = useMemo(
    () => (balanceAmount > 999999999 ? 15 : balanceAmount > 99999999 ? 18 : 22),
    [balanceAmount]
  );
  const balanceAmountPenny = useMemo(() => Math.abs(balanceAmount) % 100, [
    balanceAmount,
  ]);
  const balanceAmountValue = useMemo(
    () =>
      (balanceAmount > 0
        ? Math.floor(balanceAmount / 100)
        : Math.ceil(balanceAmount / 100)
      )?.toLocaleString("ru"),
    [balanceAmount]
  );
  let amountDescription = useMemo(() => {
    if (balanceAmount < 0) {
      return "Рекомендуем пополнить баланс";
    } else {
      switch (bankType) {
        case bankTypes["payselection"]:
          return "У вас на балансе в ZenPay";
        default:
          return "У вас на расчетном счете в банке";
      }
    }
  }, [balanceAmount, bankType]);

  const active = useMemo(
    () => [...topNavList, ...bottomNavList].find(({ isActive }) => isActive),
    []
  );

  useEffect(() => {
    if (active && active?.path) {
      setOpenedMenu(active?.path);
    }
  }, [active]);

  const handleTopUpAccountClick = () => {
    setIsTopUpModalOpen(true);
  }

  return (
    <StyledSideBarWrapper>
      <StyledSideBarTopSection>
        <StyledSideBarHeader>
          {parkFetchLoading ? (
            <>
              <ContentLoading height="32" />
              <EuiSpacer size="s" />
              <ContentLoading height="42" />
            </>
          ) : (
            <>
              <StyledSideBarHeaderTitle
                size="s"
                className={balanceAmount < 0 && "negative"}
              >
                <StyledSideBarAmountBlock fontSize={fontSize}>
                  {balanceAmount ? (
                    <>
                      ₽ {balanceAmountValue}
                      <StyledSideBarCents>
                        , {balanceAmountPenny?.toString().padStart(2, "0")}
                      </StyledSideBarCents>
                    </>
                  ) : (
                    userName
                  )}
                </StyledSideBarAmountBlock>
              </StyledSideBarHeaderTitle>
              <EuiSpacer size="s" />
              <StyledSideBarHeaderSubtitle size="s">
                {balanceAmount ? (
                  amountDescription
                ) : (
                  <StyledSideBarEmail>{userEmail}</StyledSideBarEmail>
                )}
              </StyledSideBarHeaderSubtitle>

              {isTochkaBankType && !parkCredentialsLoading && (
                <StyledSideBarHeaderButtons>
                  <PrimaryButton onClick={handleTopUpAccountClick}>
                    Пополнить счет
                  </PrimaryButton>
                </StyledSideBarHeaderButtons>
              )}
            </>
          )}
        </StyledSideBarHeader>
        <Horizontal />
        <StyledSideBarTopNav>
          <StyledSideBarNavGroup isCollapsible background="none">
            <StyledSideBarMenuNavList flush gutterSize="m">
              <NavList navList={topNavList} />
            </StyledSideBarMenuNavList>
          </StyledSideBarNavGroup>
        </StyledSideBarTopNav>
      </StyledSideBarTopSection>
      <StyledSideBarBottomSection>
        <EuiSpacer size="l" />
        <Horizontal />
        <StyledSideBarBottomNav>
          <StyledSideBarMenuNavList flush={true} gutterSize="m">
            <NavList navList={bottomNavList} />
          </StyledSideBarMenuNavList>
        </StyledSideBarBottomNav>
      </StyledSideBarBottomSection>
      <EuiSpacer size="l" />
      <StyledSideBarVersion>v {appVersion}</StyledSideBarVersion>
      <EuiSpacer size="xl" />
      {isTochkaBankType && (
        <TochkaDepositModal
          isModalOpen={isTopUpModalOpen}
          setIsModalOpen={setIsTopUpModalOpen}
        />
      )}
    </StyledSideBarWrapper>
  );
};

export default memo(SideMenu);
