import React from 'react';
import styled from 'styled-components';
import {
  EuiIcon,
  EuiTitle
} from '@elastic/eui';

const Wrapper = styled.div`
  box-shadow: 0px 0.9px 4px -1px rgba(109, 152, 184, 0.08), 0px 2.6px 8px -1px rgba(109, 152, 184, 0.06), 0px 5.7px 12px -1px rgba(109, 152, 184, 0.05), 0px 15px 15px -1px rgba(109, 152, 184, 0.04);
  border-radius: 6px;
  padding: 16px 24px 16px 16px;
  display: flex;
  justify-content: space-between;
  @media ${({ theme }) => theme.media.phone} {
    flex-direction: column;
    padding: 20px 16px;
  }
`;

const Body = styled.div`
  display: flex;
  width: 100%;
  column-gap: 16px;
  @media ${({ theme }) => theme.media.phone} {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

const Icon = styled(EuiIcon)`
  align-self: flex-start;
  width:28px;
  height: 28px;
  @media ${({ theme }) => theme.media.phone} {
    margin-right: 0;
    width: 40px;
    height: 40px;
  }
`;

const Content = styled.div`
  width: 100%;
  gap: 36px;
  display: flex;
  align-items: end;
  @media ${({ theme }) => theme.media.phone} {
    justify-content: space-between;
    gap: 4px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
`

const Title = styled(EuiTitle)`
  @media ${({ theme }) => theme.media.phone} {
    font-size: 19px;
    line-height: 28px;
  }
`;

const Subtitle = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.textColor};
  @media ${({ theme }) => theme.media.phone} {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.colorDarkShade};
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  @media ${({ theme }) => theme.media.phone} {
    display: block;
    margin-top: 16px;
  }
`;

const SubscribeStatusText = styled.div`
  color: ${({ theme }) => theme.colors.colorSuccessText};
  font-size: 14px;
  text-align: end;
  font-weight: 600;
`

export const HorizontalCard = (props) => {
  const { className, title, subtitle, footer, statusSubscribe } = props
  return (
    <Wrapper
      className={className}
    >
      <Body>
        <Content
          className='cardContent'
        >
          <TitleContainer>
            <Subtitle>
              {subtitle}
            </Subtitle>
            <Title>
              <h3>{title}</h3>
            </Title>
          </TitleContainer>
          <SubscribeStatusText>
            {statusSubscribe}
          </SubscribeStatusText>
        </Content>
      </Body>
      <Footer>
        {footer}
      </Footer>
    </Wrapper>
  )
}
