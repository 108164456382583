import BasePageBody from "components/BasePageBody/BasePageBody";
import NestedRoutes from "utils/NestedRoutes";
import React, { Fragment, useEffect, useMemo } from "react";
import BaseSidebar from "components/BaseSidebar/BaseSidebar";
import FixedIconFlyout from "components/FixedIconFlyout/FixedIconFlyout";
import CloudCashboxCard from "containers/SettingsPage/components/CloudCashboxCard";
import EdgvrCard from "containers/SettingsPage/components/EdgvrCard";
import {
  EmployeeSidebarCards,
  EmployeeTabs,
  EmployeeTitle,
} from "containers/EmployeePage/styles";
import { useBreakpoints } from "utils/useBreakpoints";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "utils/useRouter";
import parkActions from "store/park/parkActions";
import { receiptActions } from "store/receipt/receiptActions";

const tabs = [
  {
    id: "list",
    contentOnly: false,
    name: <>Сотрудники</>,
  },
  {
    id: "log",
    contentOnly: false,
    name: <>Журнал</>,
  },
];

export const EmployeeLayout = (props) => {
  const {
    isDesktop,
    isDesktopMedium,
  } = useBreakpoints();

  const router = useRouter();
  const tabMatch = router.pathname.match(/^\/\w+\/([-\w]+)/);
  const tab = tabMatch ? tabMatch[1] : null;
  const isAction = useMemo(() => tab === "action", [tab]);

  const onTabClick = ({ id }) => router.history.push(`/employee/${id}`);

  return (
    <>
      <BasePageBody>
        {!isAction && (
          <>
            <EmployeeTitle size="l">
              <h1>Сотрудники</h1>
            </EmployeeTitle>

            <EmployeeTabs
              tabs={tabs}
              onTabClick={onTabClick}
              selectedTabID={tab}
            />
          </>
        )}

        <NestedRoutes routes={props.routes} match={props.match} />
      </BasePageBody>

      {(isDesktop || isDesktopMedium) && <BaseSidebar />}
    </>
  );
};
