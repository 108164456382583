import React from 'react';
import styled from 'styled-components';
import { EuiButton } from '@elastic/eui';

const variants = {
  default: {
    activeTextColor: 'colorEmptyShade',
    inactiveTextColor: 'colorDarkestShade',
    activeBgColor: 'colorPrimary',
    inactiveBgColor: '#E8ECF1',
  },
  primary: {
    activeTextColor: 'blue',
    inactiveTextColor: 'gray',
    activeBgColor: 'lightblue',
    inactiveBgColor: 'darkgray',
  },
};

const StyledButton = styled(EuiButton)`
  color: ${({ theme, textColor }) => theme.colors[textColor] || textColor};
  background-color: ${({ theme, bgColor }) => theme.colors[bgColor] || bgColor};

  &:not([class*='isDisabled']):hover,
  &:not([class*='isDisabled']):focus {
    transform: none;
    text-decoration: none;
    background-color: ${({ theme, bgColor }) => theme.colors[bgColor] || bgColor};
    color: ${({ theme, textColor }) => theme.colors[textColor] || textColor};
  }
`;

export const ChangeButton = ({ isActive, onClick, children, variant = 'default', }) => {
  const currentVariant = variants[variant];

  const textColor = isActive ? currentVariant.activeTextColor : currentVariant.inactiveTextColor;
  const bgColor = isActive ? currentVariant.activeBgColor : currentVariant.inactiveBgColor;

  return (
    <StyledButton
      onClick={onClick}
      textColor={textColor}
      bgColor={bgColor}
    >
      {children}
    </StyledButton>
  );
};
