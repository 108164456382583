import parkActionTypes from './parkActionTypes.js'

const initialState = {
  details: {
    loading: true,
    data: null,
  },
  creds: {
    loading: true,
    data: null,
  },
  technicalSupport: {
    data: null,
    loading: true,
    accessDenied: false
  },
  updateTechnicalSupport: {
    loading: false,
  },
  aggregators: {
    loading: true,
    data: null,
    accessDenied: false
  },
  credentials: {
    loading: true,
    data: null,
    errors: null,
    accessDenied: false
  },
  alfaCertificate: {
    loading: true,
    data: null,
    errors: null,
    accessDenied: false
  },
  tinkoffKey: {
    loading: true,
    data: null,
    errors: null,
    accessDenied: false
  },
  paymentOrder: {
    loading: false,
  },
  edgvrApiKey: {
    loading: true,
    data: null,
    accessDenied: false,
    errors: null,
  },
  allPaymentSettings: {
    loading: false,
    pagination: {
      pageSize: 10,
      pageIndex: 0
    },
    data: null,
    accessDenied: false,
  },
  assignPaymentSettings: {
    loading: false
  },
  notifications: {
    loading: false,
    data: null,
  },
  deletePaymentSettings: {
    loading: false,
  },
  updatePaymentSettings: {
    loading: false,
  },
  paymentSettingsDrivers: {
    loading: false,
    data: null
  },
  report: {
    loading: false
  },
  notificationsSettings: {
    loading: false,
    data: null,
    accessDenied: false
  },
  notificationsSettingsTelegram: {
    loading: false,
    data: null
  },
  setNotificationsSettingsTelegram: {
    loading: false,
  },
  deleteNotificationsSettingsTelegram: {
    loading: false,
  },
  promo: {
    message: null,
    textColor: null,
    backgroundColor: null,
    url: null,
    height: 0
  },
  withdrawToDeposit: {
    loading: false
  },
  notifyBot: {
    loading: false
  }
}

const reducers = {
  [parkActionTypes.PARK_DETAILS_REQUEST]: (state, payload) => {
    return {
      ...state,
      details: {
        ...state.details,
        loading: true,
        data: null,
      }
    }
  },
  [parkActionTypes.PARK_DETAILS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      details: {
        ...state.details,
        loading: false,
        data: payload.details,
      }
    }
  },
  [parkActionTypes.PARK_DETAILS_FAIL]: (state, payload) => {
    return {
      ...state,
      details: {
        ...state.details,
        loading: false,
        data: null,
      }
    }
  },

  [parkActionTypes.PARK_TECHNICAL_SUPPORT_REQUEST]: (state, payload) => {
    return {
      ...state,
      technicalSupport: {
        ...state.technicalSupport,
        loading: true,
        data: null,
        accessDenied: false
      }
    }
  },
  [parkActionTypes.PARK_TECHNICAL_SUPPORT_FULFILLED]: (state, payload) => {
    return {
      ...state,
      technicalSupport: {
        ...state.technicalSupport,
        loading: false,
        data: payload.data,
        accessDenied: payload?.accessDenied
      }
    }
  },
  [parkActionTypes.PARK_TECHNICAL_SUPPORT_FAIL]: (state, payload) => {
    return {
      ...state,
      technicalSupport: {
        ...state.technicalSupport,
        data: null,
        loading: false,
        accessDenied: payload?.accessDenied
      }
    }
  },

  [parkActionTypes.PARK_CREDS_REQUEST]: (state, payload) => ({
    ...state,
    creds: {
      ...state.creds,
      loading: true,
      data: null,
      accessDenied: false,
    },
  }),
  [parkActionTypes.PARK_CREDS_FULFILLED]: (state, payload) => ({
    ...state,
    creds: {
      ...state.creds,
      loading: false,
      data: payload,
      accessDenied: payload?.accessDenied,
    },
  }),
  [parkActionTypes.PARK_CREDS_FAIL]: (state, payload) => ({
    ...state,
    creds: {
      ...state.creds,
      data: null,
      loading: false,
      accessDenied: payload?.accessDenied,
    },
  }),

  [parkActionTypes.PARK_CREDS_RESET_REQUEST]: (state, payload) => ({
    ...state,
    creds: {
      ...state.creds,
      loading: true,
      data: null,
      accessDenied: false,
    },
  }),
  [parkActionTypes.PARK_CREDS_RESET_FULFILLED]: (state, payload) => ({
    ...state,
    creds: {
      ...state.creds,
      loading: false,
      data: payload,
      accessDenied: payload?.accessDenied,
    },
  }),
  [parkActionTypes.PARK_CREDS_RESET_FAIL]: (state, payload) => ({
    ...state,
    creds: {
      ...state.creds,
      data: null,
      loading: false,
      accessDenied: payload?.accessDenied,
    },
  }),

  [parkActionTypes.PARK_UPDATE_TECHNICAL_SUPPORT_REQUEST]: (state, payload) => {
    return {
      ...state,
      updateTechnicalSupport: {
        ...state.updateTechnicalSupport,
        loading: true,
      }
    }
  },
  [parkActionTypes.PARK_UPDATE_TECHNICAL_SUPPORT_FULFILLED]: (state, payload) => {
    return {
      ...state,
      technicalSupport: {
        ...state.technicalSupport,
        data: payload.data,
      },
      updateTechnicalSupport: {
        ...state.updateTechnicalSupport,
        loading: false,
      }
    }
  },
  [parkActionTypes.PARK_UPDATE_TECHNICAL_SUPPORT_FAIL]: (state, payload) => {
    return {
      ...state,
      updateTechnicalSupport: {
        ...state.updateTechnicalSupport,
        loading: false,
      }
    }
  },

  [parkActionTypes.PARK_FETCH_CREDENTIALS_REQUEST]: (state, payload) => {
    return {
      ...state,
      credentials: {
        ...state.credentials,
        data: null,
        loading: true,
        accessDenied: false
      }
    }
  },
  [parkActionTypes.PARK_FETCH_CREDENTIALS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      credentials: {
        ...state.credentials,
        data: payload,
        loading: false,
        accessDenied: payload?.accessDenied
      }
    }
  },
  [parkActionTypes.PARK_FETCH_CREDENTIALS_FAIL]: (state, payload) => {
    return {
      ...state,
      credentials: {
        ...state.credentials,
        data: null,
        loading: false,
      }
    }
  },

  [parkActionTypes.PARK_FETCH_ALFA_CERTIFICATE_REQUEST]: (state, payload) => {
    return {
      ...state,
      alfaCertificate: {
        ...state.alfaCertificate,
        data: null,
        loading: true,
      }
    }
  },
  [parkActionTypes.PARK_FETCH_ALFA_CERTIFICATE_FULFILLED]: (state, payload) => {
    return {
      ...state,
      alfaCertificate: {
        ...state.alfaCertificate,
        data: payload,
        loading: false,
      }
    }
  },
  [parkActionTypes.PARK_FETCH_ALFA_CERTIFICATE_FAIL]: (state, payload) => {
    return {
      ...state,
      alfaCertificate: {
        ...state.alfaCertificate,
        data: null,
        loading: false,
        accessDenied: payload?.accessDenied
      }
    }
  },


  [parkActionTypes.PARK_FETCH_TINKOFF_KEY_REQUEST]: (state, payload) => {
    return {
      ...state,
      tinkoffKey: {
        ...state.tinkoffKey,
        data: null,
        loading: true,
        accessDenied: false
      }
    }
  },
  [parkActionTypes.PARK_FETCH_TINKOFF_KEY_FULFILLED]: (state, payload) => {
    return {
      ...state,
      tinkoffKey: {
        ...state.tinkoffKey,
        data: payload,
        loading: false,
        accessDenied: payload?.accessDenied
      }
    }
  },
  [parkActionTypes.PARK_FETCH_TINKOFF_KEY_FAIL]: (state, payload) => {
    return {
      ...state,
      tinkoffKey: {
        ...state.tinkoffKey,
        data: null,
        loading: false,
      }
    }
  },

  [parkActionTypes.PARK_FETCH_AGGREGATORS_REQUEST]: (state, payload) => {
    return {
      ...state,
      aggregators: {
        ...state.aggregators,
        data: null,
        loading: true,
      }
    }
  },
  [parkActionTypes.PARK_FETCH_AGGREGATORS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      aggregators: {
        ...state.aggregators,
        data: payload,
        loading: false,
        accessDenied: false
      }
    }
  },
  [parkActionTypes.PARK_FETCH_AGGREGATORS_FAIL]: (state, payload) => {
    return {
      ...state,
      aggregators: {
        ...state.aggregators,
        data: null,
        loading: false,
        accessDenied: payload?.accessDenied
      }
    }
  },

  [parkActionTypes.PARK_CREATE_PAYMENT_ORDER_REQUEST]: (state, payload) => {
    return {
      ...state,
      paymentOrder: {
        ...state.paymentOrder,
        loading: true,
      }
    }
  },
  [parkActionTypes.PARK_CREATE_PAYMENT_ORDER_FULFILLED]: (state, payload) => {
    return {
      ...state,
      paymentOrder: {
        ...state.paymentOrder,
        loading: false,
      }
    }
  },
  [parkActionTypes.PARK_CREATE_PAYMENT_ORDER_FAIL]: (state, payload) => {
    return {
      ...state,
      paymentOrder: {
        ...state.paymentOrder,
        loading: false,
      }
    }
  },

  [parkActionTypes.PARK_FETCH_EDGVR_API_KEY_REQUEST]: (state, payload) => {
    return {
      ...state,
      edgvrApiKey: {
        ...state.edgvrApiKey,
        data: null,
        loading: true,
      }
    }
  },
  [parkActionTypes.PARK_FETCH_EDGVR_API_KEY_FULFILLED]: (state, payload) => {
    return {
      ...state,
      edgvrApiKey: {
        ...state.edgvrApiKey,
        data: payload,
        loading: false,
        accessDenied: false
      }
    }
  },
  [parkActionTypes.PARK_FETCH_EDGVR_API_KEY_FAIL]: (state, payload) => {
    return {
      ...state,
      edgvrApiKey: {
        ...state.edgvrApiKey,
        data: null,
        loading: false,
        accessDenied: payload?.accessDenied
      }
    }
  },

  [parkActionTypes.PARK_FETCH_ALL_PAYMENT_SETTINGS_REQUEST]: (state, payload) => {
    return {
      ...state,
      allPaymentSettings: {
        ...state.allPaymentSettings,
        loading: true,
        data: null,
      }
    }
  },
  [parkActionTypes.PARK_FETCH_ALL_PAYMENT_SETTINGS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      allPaymentSettings: {
        ...state.allPaymentSettings,
        loading: false,
        data: payload.allPaymentSettings,
        accessDenied: false
      }
    }
  },
  [parkActionTypes.PARK_FETCH_ALL_PAYMENT_SETTINGS_FAIL]: (state, payload) => {
    return {
      ...state,
      allPaymentSettings: {
        ...state.allPaymentSettings,
        loading: false,
        data: null,
        accessDenied: payload?.accessDenied
      }
    }
  },

  [parkActionTypes.PARK_SAVE_PAYMENT_SETTINGS_PAGINATION]: (state, payload) => {
    return {
      ...state,
      allPaymentSettings: {
        ...state.allPaymentSettings,
        pagination: {
          ...state.allPaymentSettings.pagination,
          ...payload
        }
      }
    }
  },

  [parkActionTypes.PARK_ASSIGN_PAYMENT_SETTINGS_REQUEST]: (state, payload) => {
    return {
      ...state,
      assignPaymentSettings: {
        ...state.assignPaymentSettings,
        loading: true,
      }
    }
  },
  [parkActionTypes.PARK_ASSIGN_PAYMENT_SETTINGS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      assignPaymentSettings: {
        ...state.assignPaymentSettings,
        loading: false,
      }
    }
  },
  [parkActionTypes.PARK_ASSIGN_PAYMENT_SETTINGS_FAIL]: (state, payload) => {
    return {
      ...state,
      assignPaymentSettings: {
        ...state.assignPaymentSettings,
        loading: false,
      }
    }
  },

  [parkActionTypes.PARK_FETCH_NOTIFICATIONS_REQUEST]: (state, payload) => {
    return {
      ...state,
      notifications: {
        ...state.notifications,
        loading: true,
        data: null,
      }
    }
  },
  [parkActionTypes.PARK_FETCH_NOTIFICATIONS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      notifications: {
        ...state.notifications,
        loading: false,
        data: payload.notifications,
      }
    }
  },
  [parkActionTypes.PARK_FETCH_NOTIFICATIONS_FAIL]: (state, payload) => {
    return {
      ...state,
      notifications: {
        ...state.notifications,
        loading: false,
        data: null,
      }
    }
  },

  [parkActionTypes.PARK_DELETE_PAYMENT_SETTINGS_REQUEST]: (state, payload) => {
    return {
      ...state,
      deletePaymentSettings: {
        ...state.deletePaymentSettings,
        loading: true,
      }
    }
  },
  [parkActionTypes.PARK_DELETE_PAYMENT_SETTINGS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      deletePaymentSettings: {
        ...state.deletePaymentSettings,
        loading: false,
      }
    }
  },
  [parkActionTypes.PARK_DELETE_PAYMENT_SETTINGS_FAIL]: (state, payload) => {
    return {
      ...state,
      deletePaymentSettings: {
        ...state.deletePaymentSettings,
        loading: false,
      }
    }
  },

  [parkActionTypes.PARK_UPDATE_PAYMENT_SETTINGS_REQUEST]: (state, payload) => {
    return {
      ...state,
      updatePaymentSettings: {
        ...state.updatePaymentSettings,
        loading: true,
      }
    }
  },
  [parkActionTypes.PARK_UPDATE_PAYMENT_SETTINGS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      updatePaymentSettings: {
        ...state.updatePaymentSettings,
        loading: false,
      }
    }
  },
  [parkActionTypes.PARK_UPDATE_PAYMENT_SETTINGS_FAIL]: (state, payload) => {
    return {
      ...state,
      updatePaymentSettings: {
        ...state.updatePaymentSettings,
        loading: false,
      }
    }
  },

  [parkActionTypes.PARK_FETCH_PAYMENT_SETTINGS_DRIVERS_REQUEST]: (state, payload) => {
    return {
      ...state,
      paymentSettingsDrivers: {
        ...state.paymentSettingsDrivers,
        loading: true,
        data: null
      }
    }
  },
  [parkActionTypes.PARK_FETCH_PAYMENT_SETTINGS_DRIVERS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      paymentSettingsDrivers: {
        ...state.paymentSettingsDrivers,
        loading: false,
        data: payload.paymentSettingsDrivers,
      }
    }
  },
  [parkActionTypes.PARK_FETCH_PAYMENT_SETTINGS_DRIVERS_FAIL]: (state, payload) => {
    return {
      ...state,
      paymentSettingsDrivers: {
        ...state.paymentSettingsDrivers,
        loading: false,
        data: null
      }
    }
  },

  [parkActionTypes.PARK_CREATE_REPORT_REQUEST]: (state, payload) => {
    return {
      ...state,
      report: {
        ...state.report,
        loading: true,
      }
    }
  },
  [parkActionTypes.PARK_CREATE_REPORT_FULFILLED]: (state, payload) => {
    return {
      ...state,
      report: {
        ...state.report,
        loading: false,
      }
    }
  },
  [parkActionTypes.PARK_CREATE_REPORT_FAIL]: (state, payload) => {
    return {
      ...state,
      report: {
        ...state.report,
        loading: false,
      }
    }
  },

  [parkActionTypes.PARK_FETCH_NOTIFICATIONS_SETTINGS_REQUEST]: (state, payload) => {
    return {
      ...state,
      notificationsSettings: {
        ...state.notificationsSettings,
        loading: true,
        data: null,
        accessDenied: false
      }
    }
  },
  [parkActionTypes.PARK_FETCH_NOTIFICATIONS_SETTINGS_FULFILLED]: (state, payload) => {
    return {
      ...state,
      notificationsSettings: {
        ...state.notificationsSettings,
        loading: false,
        data: payload.notificationsSettings,
        accessDenied: payload.false
      }
    }
  },
  [parkActionTypes.PARK_FETCH_NOTIFICATIONS_SETTINGS_FAIL]: (state, payload) => {
    return {
      ...state,
      notificationsSettings: {
        ...state.notificationsSettings,
        loading: false,
        data: null,
        accessDenied: payload?.accessDenied
      }
    }
  },

  [parkActionTypes.PARK_FETCH_NOTIFICATIONS_SETTINGS_TELEGRAM_REQUEST]: (state, payload) => {
    return {
      ...state,
      notificationsSettingsTelegram: {
        ...state.notificationsSettingsTelegram,
        loading: true,
        data: null
      }
    }
  },
  [parkActionTypes.PARK_FETCH_NOTIFICATIONS_SETTINGS_TELEGRAM_FULFILLED]: (state, payload) => {
    return {
      ...state,
      notificationsSettingsTelegram: {
        ...state.notificationsSettingsTelegram,
        loading: false,
        data: payload.notificationsSettingsTelegram,
      }
    }
  },
  [parkActionTypes.PARK_FETCH_NOTIFICATIONS_SETTINGS_TELEGRAM_FAIL]: (state, payload) => {
    return {
      ...state,
      notificationsSettingsTelegram: {
        ...state.notificationsSettingsTelegram,
        loading: false,
        data: null
      }
    }
  },

  [parkActionTypes.PARK_SET_NOTIFICATIONS_SETTINGS_TELEGRAM_REQUEST]: (state, payload) => {
    return {
      ...state,
      setNotificationsSettingsTelegram: {
        ...state.setNotificationsSettingsTelegram,
        loading: true,
      }
    }
  },
  [parkActionTypes.PARK_SET_NOTIFICATIONS_SETTINGS_TELEGRAM_FULFILLED]: (state, payload) => {
    return {
      ...state,
      setNotificationsSettingsTelegram: {
        ...state.setNotificationsSettingsTelegram,
        loading: false,
      }
    }
  },
  [parkActionTypes.PARK_SET_NOTIFICATIONS_SETTINGS_TELEGRAM_FAIL]: (state, payload) => {
    return {
      ...state,
      setNotificationsSettingsTelegram: {
        ...state.setNotificationsSettingsTelegram,
        loading: false,
      }
    }
  },

  [parkActionTypes.PARK_DELETE_NOTIFICATIONS_SETTINGS_TELEGRAM_REQUEST]: (state, payload) => {
    return {
      ...state,
      deleteNotificationsSettingsTelegram: {
        ...state.deleteNotificationsSettingsTelegram,
        loading: true,
      }
    }
  },
  [parkActionTypes.PARK_DELETE_NOTIFICATIONS_SETTINGS_TELEGRAM_FULFILLED]: (state, payload) => {
    return {
      ...state,
      deleteNotificationsSettingsTelegram: {
        ...state.deleteNotificationsSettingsTelegram,
        loading: false,
      }
    }
  },
  [parkActionTypes.PARK_DELETE_NOTIFICATIONS_SETTINGS_TELEGRAM_FAIL]: (state, payload) => {
    return {
      ...state,
      deleteNotificationsSettingsTelegram: {
        ...state.deleteNotificationsSettingsTelegram,
        loading: false,
      }
    }
  },

  [parkActionTypes.PARK_FETCH_PROMO_REQUEST]: (state, payload) => {
    return {
      ...state,
      promo: {
        ...state.promo,
      }
    }
  },
  [parkActionTypes.PARK_FETCH_PROMO_FULFILLED]: (state, payload) => {
    return {
      ...state,
      promo: {
        ...state.promo,
        ...payload.promo
      }
    }
  },
  [parkActionTypes.PARK_FETCH_PROMO_FAIL]: (state, payload) => {
    return {
      ...state,
      promo: {
        ...state.promo,
      }
    }
  },

  [parkActionTypes.SET_PROMO_HEIGHT]: (state, payload) => {
    return {
      ...state,
      promo: {
        ...state.promo,
        height: payload.height
      }
    }
  },

  [parkActionTypes.PARK_WITHDRAW_TO_DEPOSIT_REQUEST]: (state, payload) => {
    return {
      ...state,
      withdrawToDeposit: {
        ...state.withdrawToDeposit,
        loading: true,
      }
    }
  },
  [parkActionTypes.PARK_WITHDRAW_TO_DEPOSIT_FULFILLED]: (state, payload) => {
    return {
      ...state,
      withdrawToDeposit: {
        ...state.withdrawToDeposit,
        loading: false,
      }
    }
  },
  [parkActionTypes.PARK_WITHDRAW_TO_DEPOSIT_FAIL]: (state, payload) => {
    return {
      ...state,
      withdrawToDeposit: {
        ...state.withdrawToDeposit,
        loading: false,
      }
    }
  },
  [parkActionTypes.PARK_NOTIFY_BOT_REQUEST]: (state, payload) => {
    return {
      ...state,
      notifyBot: {
        ...state.notifyBot,
        loading: true,
      }
    }
  },
  [parkActionTypes.PARK_NOTIFY_BOT_FULFILLED]: (state, payload) => {
    return {
      ...state,
      notifyBot: {
        ...state.notifyBot,
        loading: false,
      }
    }
  },
  [parkActionTypes.PARK_NOTIFY_BOT_FAIL]: (state, payload) => {
    return {
      ...state,
      notifyBot: {
        ...state.notifyBot,
        loading: false,
      }
    }
  },
  [parkActionTypes.PARK_FUEL_ENABLED_REQUEST]: (state, payload) => {
    return {
      ...state,
      details: {
        ...state.details,
        data: {
          ...state.details.data,
          fuel_enabled: payload,
        },
      },
    }
  },
  [parkActionTypes.PARK_FUEL_ENABLED_FULFILLED]: (state, payload) => {
    return {
      ...state,
      details: {
        ...state.details,
        data: {
          ...state.details.data,
          fuel_enabled: payload,
        },
      },
    }
  },
  [parkActionTypes.PARK_FUEL_ENABLED_FAIL]: (state, payload) => {
    return {
      ...state,
      details: {
        ...state.details,
        data: {
          ...state.details.data,
          fuel_enabled: payload,
        },
      },
    }
  },
}

export default function parkReducer(state = initialState, action) {
  if (reducers[action.type]) {
    return reducers[action.type](state, action.payload)
  }
  return state
};
