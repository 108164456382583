import { EuiIcon } from "@elastic/eui";
import React from "react";
import { matchPath } from "react-router";
import { useRouter } from "utils/useRouter";
import {
  StyledSideBarInsuranceMenuItem,
  StyledSideBarPaymentsMenuItem,
  StyledSideBarPaymentsQuantity,
} from "components/SideMenu/styles";
import { useSelector } from "react-redux";
import { selectTotalCounters } from "components/SideMenu/model/selectors";

import { ReactComponent as StaffIcon } from "assets/images/staff.svg";
import { ReactComponent as InfoCenterIcon } from "assets/images/info-center.svg";
import { ReactComponent as InsuranceIcon } from "assets/images/insurance.svg";
import { ReactComponent as AlfaOSAGOIcon } from "assets/images/notification_badge.svg";
import { ReactComponent as SubscribeIcon } from "assets/images/Union.svg";
import { ReactComponent as PaymentsGroup } from "assets/images/database.svg";
import { useSideBar } from "components/SideMenu/model/context";

export const useNavList = () => {
  const router = useRouter();

  const { openedMenu, setOpenedMenu } = useSideBar();

  const hasMatchPath = (path) => !!matchPath(router.pathname, { path });
  const onRedirect = ({ path, redirectPath, menu }) => () => {
    if (menu) {
      const opened = path === openedMenu ? null : path;
      setOpenedMenu(opened);

      if (opened === null) {
        return;
      }
    }

    router.history.push(redirectPath || path);
  };

  const totalCounters = useSelector(selectTotalCounters);

  const paymentsLink = (
    <StyledSideBarPaymentsMenuItem>
      Платежи
      {!!totalCounters && (
        <StyledSideBarPaymentsQuantity>
          {totalCounters}
        </StyledSideBarPaymentsQuantity>
      )}
    </StyledSideBarPaymentsMenuItem>
  );

  const osagoNew = (
    <StyledSideBarInsuranceMenuItem>
      ОСАГО
      <EuiIcon style={{width: "24px", height: "24px"}} type={AlfaOSAGOIcon} size="m"></EuiIcon>
    </StyledSideBarInsuranceMenuItem>
  );

  const topNavList = [
    {
      label: paymentsLink,
      icons: [<EuiIcon type="database" />],
      size: "s",
      path: "/payments",
      href: "/payments",
      isActive: hasMatchPath("/payments"),
      onClick: onRedirect,
    },
    {
      label: "Группы выплат",
      icons: [<EuiIcon type={PaymentsGroup} />],
      size: "s",
      path: "/payment-settings",
      href: "/payment-settings",
      isActive: hasMatchPath("/payment-settings"),
      onClick: onRedirect,
    },
    {
      label: "Водители",
      icons: [<EuiIcon type="users" />],
      size: "s",
      path: "/drivers",
      redirectPath: "/drivers/list/all",
      href: "/drivers/list/all",
      isActive: hasMatchPath("/drivers"),
      onClick: onRedirect,
    },
    {
      label: "Бонусы",
      icons: [<EuiIcon type="controlsHorizontal" />],
      size: "s",
      path: "/referral",
      href: "/referral",
      isActive: hasMatchPath("/referral"),
      onClick: onRedirect,
    },
    {
      label: "Аналитика",
      icons: [<EuiIcon type="analyzeEvent" />],
      size: "s",
      path: "/analytics",
      redirectPath: "/analytics/income",
      href: "/analytics/income",
      isActive: hasMatchPath("/analytics"),
      onClick: onRedirect,
    },
    {
      label: "Топливо",
      size: "s",
      path: "/fuel/program/list",
      href: "/fuel/program/list",
      onClick: onRedirect,
      isActive: hasMatchPath("/fuel/"),
      icons: [<EuiIcon type="tear" size="m" />],
    },
    {
      label: "Сотрудники",
      size: "s",
      path: "/employee/list",
      href: "/employee/list",
      onClick: onRedirect,
      isActive: hasMatchPath("/employee"),
      icons: [<EuiIcon type={StaffIcon} size="m" />],
    },
    {
      label: "Инфоцентр",
      size: "s",
      path: "/info-center",
      href: "/info-center",
      onClick: onRedirect,
      isActive: hasMatchPath("/info-center/"),
      icons: [<EuiIcon type={InfoCenterIcon} size="m" />],
    },
    {
      label: osagoNew,
      icons: [<EuiIcon type={InsuranceIcon} size="m" />],
      size: "s",
      href: "https://promo.mozen.io/osago_month_5800?utm_source=erpmozen",
      target: "_blank",
    },
  ];

  const bottomNavList = [
    {
      label: "База знаний",
      href: "https://wiki.mozen.io",
      iconType: "glasses",
      size: "s",
      target: "_blank",
    },
    {
      label: "Подписка",
      size: "s",
      path: "/subscription",
      href: "/subscription",
      icons: [<EuiIcon type={SubscribeIcon} size="m" />],
      isActive: hasMatchPath("/subscription"),
      onClick: onRedirect,
    },
    {
      label: "Настройки",
      iconType: "gear",
      size: "s",
      path: "/settings",
      redirectPath: "/settings/carpark",
      href: "/settings/carpark",
      isActive: hasMatchPath("/settings"),
      onClick: onRedirect,
    },
  ];

  return {
    topNavList,
    bottomNavList,
  };
};
