import axios from "axios";
import { toast } from "utils/toast";
import { store } from "./store";
import userActionTypes from "./user/userActionTypes";

let appAxiosInstance = axiosUpdate()
let isUpdatePromise = false

const network = {
  get appAxios() {
    return appAxiosInstance
  },
  tokenUpdateTimeout: null
}

function axiosUpdate(accessToken, refreshToken) {
  const headers = {
    'x-app-id': process.env.REACT_APP_X_APP_ID,
  }

  if (!accessToken || !refreshToken) {
    return axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers,
    })
  }
  localStorage.setItem('accessToken', accessToken)
  localStorage.setItem('refreshToken', refreshToken)
  headers['x-access-jwt'] = `${accessToken}`

  appAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
  })

  const expDate = new Date(JSON.parse(atob(accessToken.split('.')[1])).exp * 1000)
  const now = new Date()
  clearTimeout(network.tokenUpdateTimeout)
  network.tokenUpdateTimeout = setTimeout(async (refresh) => {
    const refreshNow = localStorage.getItem('refreshToken')
    if (refresh !== refreshNow) {
      return
    }

    if ("locks" in navigator) {
      await navigator.locks.request('updateToken', async (lock) => {
        if (refreshNow === localStorage.getItem('refreshToken')) {
          await updateToken(refresh);
        } else {
          axiosUpdate(localStorage.getItem('accessToken'), localStorage.getItem('refreshToken'));
        }
      });
    } else {
      await updateToken(refresh);
    }

  }, expDate - now - 2e3, refreshToken)

  appAxiosInstance.interceptors.response.use(null, async error => {
    if (error.response?.status === 401) {

      if ("locks" in navigator) {
        await navigator.locks.request('updateToken', async (lock) => {
          if (refreshToken === localStorage.getItem('refreshToken')) {
            await updateToken(localStorage.getItem('refreshToken'));
          } else {
            axiosUpdate(localStorage.getItem('accessToken'), localStorage.getItem('refreshToken'));
          }
        });
      } else {
        await updateToken(localStorage.getItem('refreshToken'));
      }

      error.config.headers['x-access-jwt'] = localStorage.getItem('accessToken')
      return appAxiosInstance.request(error.config)
    }

    if (error.response?.status === 403) {
        switch (error.response.config.method) {
        case 'patch':
        case 'post':
        case 'delete':
          toast.add({
            title: 'Ошибка',
            text: 'Доступ запрещен',
            color: 'danger',
          })
        break
        default:
      }
      return Promise.reject(error)
    }
    return Promise.reject(error)
  })


  appAxiosInstance.interceptors.request.use(config => {
    return {
      ...config,
      headers,
    }
  })

  return appAxiosInstance
}

function updateToken(refreshToken) {
  if (isUpdatePromise) {
    return isUpdatePromise
  }
  return isUpdatePromise = new Promise((res, reject) => {
    const promise = axios({
      url: `${process.env.REACT_APP_API_URL}auth/1/refresh`,
      method: 'POST',
      headers: {
        'x-app-id': process.env.REACT_APP_X_APP_ID
      },
      data: {
        refresh: refreshToken
      }
    })

    promise.then(response => {
      const {access, refresh} = response.data
      axiosUpdate(access, refresh)
      res({accessToken: localStorage.getItem('accessToken'), refreshToken: localStorage.getItem('refreshToken')})
      isUpdatePromise = null
    }).catch(err => {
      if (err.response.status === 403 || err.response.status === 401) {
        store.dispatch({
          type: userActionTypes.USER_LOGOUT,
          payload: {},
        })
      }
      reject(err)
    })
  })
}


export { network, axiosUpdate, updateToken}
