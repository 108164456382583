import { all, call, put, select } from 'redux-saga/effects'
import parkActionTypes from './parkActionTypes'
import { takeLatest } from '@redux-saga/core/effects'
import { network, updateToken } from "../network";
import { bankTypes } from './model/bankTypes';
import { toast } from 'utils/toast';
import { aggregatorsTypes } from './model/aggregatorsTypes';

function* fetchDetails(action) {
  const response = yield call(network.appAxios, {
    url: `/erp/1/park/details`,
    method: 'GET',
  })

  yield put({
    type: parkActionTypes.PARK_DETAILS_FULFILLED,
    payload: {
      details: response.data,
    },
  })

  const bankType = response.data.bank_type
  const hasCredentials = [bankTypes.alfa_e2c, bankTypes.qiwi, bankTypes.modul, bankTypes.tochka, bankTypes.payselection, bankTypes.tinkoff, bankTypes.sbank].includes(bankType)

  if (hasCredentials) {
    yield put({
      type: parkActionTypes.PARK_FETCH_CREDENTIALS_REQUEST,
      payload: {},
    })
  } else {
    yield put({
      type: parkActionTypes.PARK_FETCH_CREDENTIALS_FAIL,
      payload: {},
    })
  }
}

function* fetchParkCreds() {
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/creds`,
      method: "GET",
    });

    yield put({
      type: parkActionTypes.PARK_CREDS_FULFILLED,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: parkActionTypes.PARK_CREDS_FULFILLED,
      payload: {
        items: [{
          rsa_status: null,
        }],
      },
    });
    throw error;
  }
}

function* fetchParkCredsReset() {
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/creds/reset`,
      method: "PATCH",
    });

    yield put({
      type: parkActionTypes.PARK_CREDS_RESET_FULFILLED,
      payload: response.data,
    });

    toast.add({
      title: 'Выплаты парковым ИП отключены',
      color: 'primary',
    })
  } catch (error) {
    yield put({
      type: parkActionTypes.PARK_CREDS_RESET_FAIL,
      payload: {
        items: [{
          rsa_status: null
        }],
      },
    });
  }
}

function* fetchTechnicalSupport(action) {
  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/park/technical-support-contacts`,
      method: 'GET',
    })

    yield put({
      type: parkActionTypes.PARK_TECHNICAL_SUPPORT_FULFILLED,
      payload: {
        data: response.data,
      },
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_TECHNICAL_SUPPORT_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_TECHNICAL_SUPPORT_FAIL,
        payload: { accessDenied: true }
      })
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "ParkNotFound") {
      yield put({
        type: parkActionTypes.PARK_TECHNICAL_SUPPORT_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* updateTechnicalSupport(action) {
  const {payload} = action
  const {values} = payload

  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/park/technical-support-contacts`,
      method: 'PATCH',
      data: {
        "viber": {
          "enabled": values.viberEnabled,
          "value": values.viber,
        },
        "whatsapp": {
          "enabled": values.whatsappEnabled,
          "value": values. whatsapp,
        },
        "telegram": {
          "enabled": values.telegramEnabled,
          "value": values.telegram,
        },
        "phone": values.phone,
        "email": values.email,
        "display_name": values.display_name || ''
      }
    })

    yield put({
      type: parkActionTypes.PARK_UPDATE_TECHNICAL_SUPPORT_FULFILLED,
      payload: {
        data: {
          "viber": {
            "enabled": values.viberEnabled,
            "value": values.viber,
          },
          "whatsapp": {
            "enabled": values.whatsappEnabled,
            "value": values.whatsapp,
          },
          "telegram": {
            "enabled": values.telegramEnabled,
            "value": values.telegram,
          },
          "phone": values.phone,
          "email": values.email,
          "display_name": values.display_name
        }
      },
    })

    toast.add({
      title: 'Изменения сохранены',
      text: 'Данные профиля успешно обновлены',
      color: 'success',
    })

  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось обновить данные',
        text: 'Сбой операции. Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_TECHNICAL_SUPPORT_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "ParkNotFound") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_TECHNICAL_SUPPORT_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_TECHNICAL_SUPPORT_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_TECHNICAL_SUPPORT_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* fetchCredentials() {
  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/park/payout/credentials`,
      method: 'GET',
    })
    yield put({
      type: parkActionTypes.PARK_FETCH_CREDENTIALS_FULFILLED,
      payload: response.data,
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_FETCH_CREDENTIALS_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "PayoutCredentialsNotFound") {
      yield put({
        type: parkActionTypes.PARK_FETCH_CREDENTIALS_FAIL,
        payload: err.response.data,
      })
      return
    }

     if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_FETCH_CREDENTIALS_FAIL,
        payload: {accessDenied: true},
      })
      return
    }

    throw err
  }
}


function* updateCredentials(action) {
  const {payload} = action
  const state = yield select()
  const { credentials } = state.park
  const credentialsData = credentials.data

  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/park/payout/credentials`,
      method: 'POST',
      data: payload
    })
    yield put({
      type: parkActionTypes.PARK_UPDATE_CREDENTIALS_FULFILLED,
      payload: null,
    })
    yield put({
      type: parkActionTypes.PARK_FETCH_CREDENTIALS_FULFILLED,
      payload: response.data,
    })

    toast.add({
      title: credentialsData ? 'Изменения сохранены' : `Счет успешно добавлен`,
      text: credentialsData ? 'Данные счета успешно обновлены.' : 'Все готово! Теперь можно принимать платежи и делать выплаты.',
      color: credentialsData ? 'success' : 'primary',
    })

  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось обновить данные',
        text: 'Сбой операции. Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_CREDENTIALS_FAIL,
        payload: err.response.data.payload,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ValidationError") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_CREDENTIALS_FAIL,
        payload: err.response.data.payload,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_CREDENTIALS_FAIL,
        payload: err.response.data.payload,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_CREDENTIALS_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* fetchAlfaCertificate() {
  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/park/h2h-certificate`,
      method: 'GET',
    })
    yield put({
      type: parkActionTypes.PARK_FETCH_ALFA_CERTIFICATE_FULFILLED,
      payload: response.data,
    })
  } catch (err) {
     if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_FETCH_ALFA_CERTIFICATE_FAIL,
        payload: null,
      })
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "CertificateDetailsNotFound") {
      yield put({
        type: parkActionTypes.PARK_FETCH_ALFA_CERTIFICATE_FAIL,
        payload: null,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_FETCH_ALFA_CERTIFICATE_FAIL,
        payload: { accessDenied: true }
      })
      return
    }

    if (err.response && err.response.status === 400) {
      yield put({
        type: parkActionTypes.PARK_FETCH_ALFA_CERTIFICATE_FAIL,
        payload: null,
      })
      return
    }

    throw err
  }
}

function* updateAlfaCertificate(action) {
  const { payload } = action
  const state = yield select()
  const { alfaCertificate } = state.park
  const isAlfaCertificateExists = !!alfaCertificate.data

  const formData = new FormData()
  formData.append('alias', payload.alias)
  formData.append('password', payload.password)
  formData.append('file', payload.file[0])
  formData.append('expire_date', payload.expire_date)
  formData.append('basic_pass', payload.basic_pass)
  formData.append('basic_user', payload.basic_user)

  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/park/h2h-certificate`,
      method: 'POST',
      data: formData
    })

    yield put({
      type: parkActionTypes.PARK_UPDATE_ALFA_CERTIFICATE_FULFILLED,
      payload: response.data,
    })

    toast.add({
      title: isAlfaCertificateExists ? 'Изменения сохранены' : 'Сертификат подключен',
      text: isAlfaCertificateExists ? 'Сертификат успешно обновлен.' : 'Все готово! Теперь можно принимать платежи и делать выплаты.',
      color: isAlfaCertificateExists ? 'success' : 'primary',
    })

  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось обновить',
        text: 'Сбой операции. Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_ALFA_CERTIFICATE_FAIL,
        payload: {},
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InvalidFiles") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_ALFA_CERTIFICATE_FAIL,
        payload: {file: err.response.data.message},
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "CertificateLoadError") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_ALFA_CERTIFICATE_FAIL,
        payload: {},
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_ALFA_CERTIFICATE_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* fetchTinkoffKey() {
  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/park/tinkoff`,
      method: 'GET',
    })
    yield put({
      type: parkActionTypes.PARK_FETCH_TINKOFF_KEY_FULFILLED,
      payload: response.data,
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "TinkoffCredentialsNotFound") {
      yield put({
        type: parkActionTypes.PARK_FETCH_TINKOFF_KEY_FAIL,
        payload: null,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_FETCH_TINKOFF_KEY_FAIL,
        payload: {accessDenied: true},
      })
      return
    }

    throw err
  }
}

function* updateTinkoffKey(action) {
  const {payload} = action
  const state = yield select()
  const { tinkoffKey } = state.park
  const tinkoffKeyData = tinkoffKey.data
  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/park/tinkoff`,
      method: 'PATCH',
      data: payload
    })

    yield put({
      type: parkActionTypes.PARK_UPDATE_TINKOFF_KEY_FULFILLED,
      payload: null,
    })

    yield put({
      type: parkActionTypes.PARK_FETCH_TINKOFF_KEY_FULFILLED,
      payload: response.data,
    })

    toast.add({
      title: tinkoffKeyData ? 'Изменения сохранены' : 'Счет в Тинькофф подключен',
      text: tinkoffKeyData ? 'Данные счета успешно обновлены.' : 'Все готово! Теперь можно принимать платежи и делать выплаты.',
      color: tinkoffKeyData ? 'success' : 'primary',
    })

  } catch (e) {
    if (e.response && e.response.status === 400 && e.response.data.code === "InputValidationError") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_TINKOFF_KEY_FAIL,
        payload: e.response.data.payload,
      })
      return
    }
    if (e.response && e.response.status === 500 && e.response.data.code === "TinkoffChannelIsNotEnabled") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_TINKOFF_KEY_FAIL,
        payload: {terminal_key: e.response.data.message},
      })
      return
    }
    if (e.response && e.response.status === 403 && e.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_TINKOFF_KEY_FAIL,
        payload: e.response.data,
      })
      return
    }
    throw e
  }
}

function* fetchAggregators() {
  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/park/aggregators`,
      method: 'GET',
    })
    yield put({
      type: parkActionTypes.PARK_FETCH_AGGREGATORS_FULFILLED,
      payload: response.data,
    })
  } catch (err) {
    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_FETCH_AGGREGATORS_FAIL,
        payload: { accessDenied: true }
      })
      return
    }

    throw err
  }
}

function* fetchAggregator(action) {
  const {id} = action.payload
  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/park/aggregator/${id}`,
      method: 'GET',
    })
    yield put({
      type: parkActionTypes.PARK_FETCH_AGGREGATOR_FULFILLED,
      payload: response.data,
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_FETCH_AGGREGATOR_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "YandexParkNotFound") {
      yield put({
        type: parkActionTypes.PARK_FETCH_AGGREGATOR_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* deleteAggregator(action) {
  const {id, type, name} = action.payload
  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/park/aggregator/${id}`,
      method: 'DELETE',
      data: {
        aggregator_type: type,
      },
    })

    yield put({
      type: parkActionTypes.PARK_DELETE_AGGREGATOR_FULFILLED,
      payload: response.data,
    })

    toast.add({
      title: `Аккаунт ${name} удален`,
      text: 'Водители больше не смогут увидеть свой баланс в приложении и выводить деньги.',
      color: 'danger',
    })

  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось удалить аккаунт',
        text: 'Сбой операции. Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_DELETE_AGGREGATOR_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 409 && err.response.data.code === "NotFinishedTransactions") {
      yield put({
        type: parkActionTypes.PARK_DELETE_AGGREGATOR_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "YandexParkNotFound") {
      yield put({
        type: parkActionTypes.PARK_DELETE_AGGREGATOR_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_DELETE_AGGREGATOR_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* updateAggregator(action) {
  const {id, type, data} = action.payload
  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/park/aggregator/${id}`,
      method: 'PATCH',
      data: {
        aggregator_type: type,
        aggregator_meta: data,
      },
    })

    yield put({
      type: parkActionTypes.PARK_UPDATE_AGGREGATOR_FULFILLED,
      payload: response.data,
    })

    toast.add({
      title: 'Изменения сохранены',
      text: 'Данные учетной записи агрегатора успешно сохранены',
      color: 'success',
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось обновить',
        text: 'Сбой операции. Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_AGGREGATOR_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "YandexParkNotFound") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_AGGREGATOR_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_AGGREGATOR_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_AGGREGATOR_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* createAggregator(action) {
  const payload = action.payload
  const aggregatorType = Object.keys(payload)[0]
  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/park/aggregator`,
      method: 'POST',
      data: payload,
    })

    yield put({
      type: parkActionTypes.PARK_CREATE_AGGREGATOR_FULFILLED,
      payload: response.data,
    })

    toast.add({
      title: `Новый аккаунт успешно подключен`,
      text: 'Список водителей будет доступен в течение 15 минут.',
      color: 'primary',
    })

  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось подключить',
        text: `К сожалению, не удалось подключить учетную запись ${aggregatorsTypes[aggregatorType]?.label}. Пожалуйста, повторите попытку позже.`,
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_CREATE_AGGREGATOR_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 409 && err.response.data.code === "AggregatorAccountAlreadyExist") {
      yield put({
        type: parkActionTypes.PARK_CREATE_AGGREGATOR_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "YandexFleetApiUnavailable") {
      yield put({
        type: parkActionTypes.PARK_CREATE_AGGREGATOR_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_CREATE_AGGREGATOR_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: parkActionTypes.PARK_CREATE_AGGREGATOR_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    throw err
  }
}

function* createPaymentOrder(action) {
  const {amount, payment_order_type} = action.payload
  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/park/payment-order`,
      method: 'GET',
      responseType: 'blob',
      params: {
        amount,
        payment_order_type
      },
    })
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: response.headers['content-type'] })
    )
    const link = document.createElement('a')
    link.href = url
    const contentDisposition = response.headers['content-disposition']
    let fileName = 'payment-order.txt'
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch.length === 2) {
        const newFileName = fileNameMatch[1]
        fileName = newFileName
      }
    }
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)

    yield put({
      type: parkActionTypes.PARK_CREATE_PAYMENT_ORDER_FULFILLED,
      payload: {},
    })

    toast.add({
      title: 'Платеж создан',
      text: 'Спасибо. Должно автоматически начаться скачивание файла',
      color: 'primary',
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось скачать счет',
        text: 'Произошла ошибка. Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_CREATE_PAYMENT_ORDER_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: parkActionTypes.PARK_CREATE_PAYMENT_ORDER_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    throw err
  }
}

function* fetchEdgvrApiKey() {
  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/park/edgvr-api-key`,
      method: 'GET',
    })
    yield put({
      type: parkActionTypes.PARK_FETCH_EDGVR_API_KEY_FULFILLED,
      payload: response.data,
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_FETCH_EDGVR_API_KEY_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_FETCH_EDGVR_API_KEY_FAIL,
        payload: { accessDenied: true }
      })
      return
    }

    throw err
  }
}

function* updateEdgvrApiKey(action) {
  const { payload } = action
  const state = yield select()
  const {edgvrApiKey} = state.park
  const isEdgvrApiKeyExists = !!edgvrApiKey.data?.edgvr_api_key

  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/park/change-edgvr-api-key`,
      method: 'POST',
      data: payload
    })

    yield put({
      type: parkActionTypes.PARK_UPDATE_EDGVR_API_KEY_FULFILLED,
      payload: response.data,
    })

    toast.add({
      title: isEdgvrApiKeyExists ? 'EDGVR успешно отредактирован' : 'EDGVR подключен успешно',
      text: isEdgvrApiKeyExists ? '' : 'Теперь вы можете заключать агентские договоры с водителями удаленно прямо в приложении Mozen',
      color: 'primary',
    })

  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось подключить',
        text: 'Возникла ошибка при подключении EDGVR. Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_EDGVR_API_KEY_FAIL,
        payload: null,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "EdgvrApiKeyIncorrect") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_EDGVR_API_KEY_FAIL,
        payload: {edgvr_api_key: err.response.data.message},
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_EDGVR_API_KEY_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* deleteEdgvrApiKey(action) {
  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/park/edgvr-api-key`,
      method: 'DELETE',
    })
    yield put({
      type: parkActionTypes.PARK_DELETE_EDGVR_API_KEY_FULFILLED,
      payload: {},
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_EDGVR_API_KEY_FAIL,
        payload: err.response.data,
      })
      toast.add({
        title: 'Не удалось удалить',
        text: 'Возникла ошибка. Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_EDGVR_API_KEY_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}


function* fetchAllPaymentSettings(action) {
  const state = yield select()
  const {park: {allPaymentSettings: {pagination: {
    pageIndex,
    pageSize
  }}}} = state;

  // const {offset, limit} = action.payload;

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/payment-settings/all`,
      method: 'GET',
      params: {
        offset: pageIndex * pageSize,
        limit: pageSize
      }
    })

    yield put({
      type: parkActionTypes.PARK_FETCH_ALL_PAYMENT_SETTINGS_FULFILLED,
      payload: {
        allPaymentSettings: response.data
      }
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_FETCH_ALL_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_FETCH_ALL_PAYMENT_SETTINGS_FAIL,
        payload: {accessDenied: true},
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: parkActionTypes.PARK_FETCH_ALL_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* assignPaymentSettings(action) {
  const { user_ids, payment_settings_id } = action.payload

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/payment-settings/${payment_settings_id}/assign`,
      method: 'PATCH',
      data: {
        user_ids
      }
    })

    yield put({
      type: parkActionTypes.PARK_ASSIGN_PAYMENT_SETTINGS_FULFILLED,
      payload: {}
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось изменить',
        text: 'Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_ASSIGN_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "UserNotFound") {
      yield put({
        type: parkActionTypes.PARK_ASSIGN_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: parkActionTypes.PARK_ASSIGN_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_ASSIGN_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* fetchNotifications(action) {
  const { limit, offset } = action.payload
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/notifications/mass`,
      method: 'GET',
      params: {
        limit,
        offset
      }
    })

    yield put({
      type: parkActionTypes.PARK_FETCH_NOTIFICATIONS_FULFILLED,
      payload: {
        notifications: response.data
      }
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_FETCH_NOTIFICATIONS_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: parkActionTypes.PARK_FETCH_NOTIFICATIONS_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* createMassNotification(action) {
  const { text, aggregators } = action.payload
  const aggregatorsNames = aggregators.reduce((array, { label }) => { array.push(label); return array }, [])
  const aggregatorsNamesPop = aggregatorsNames.length > 1 ? aggregatorsNames.pop() : ''
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/notifications/mass`,
      method: 'POST',
      data: {
        text,
        aggregators
      }
    })

    yield put({
      type: parkActionTypes.PARK_CREATE_MASS_NOTIFICATION_FULFILLED,
      payload: {}
    })

    toast.add({
      title: 'Уведомление успешно отправлено',
      text: `Скоро все водители автопарка ${
        !!aggregatorsNamesPop
          ? aggregatorsNames.join(', ') + ` и  ${aggregatorsNamesPop}`
          : aggregatorsNames[0]
      } получат ваше сообщение в мобильном приложении`,
      color: 'primary',
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось отправить',
        text: 'Произошла ошибка. Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_CREATE_MASS_NOTIFICATION_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: parkActionTypes.PARK_CREATE_MASS_NOTIFICATION_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_CREATE_MASS_NOTIFICATION_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* updatePaymentSettings(action) {
  const { payment_settings_id, data } = action.payload

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/payment-settings/${payment_settings_id}`,
      method: 'PATCH',
      data
    })

    yield put({
      type: parkActionTypes.PARK_UPDATE_PAYMENT_SETTINGS_FULFILLED,
      payload: {}
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось изменить',
        text: 'Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 404 && err.response.data.code === "ParkNotFound") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* deletePaymentSettings(action) {
  const { payment_settings_id } = action.payload

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/payment-settings/${payment_settings_id}`,
      method: 'DELETE',
    })

    yield put({
      type: parkActionTypes.PARK_DELETE_PAYMENT_SETTINGS_FULFILLED,
      payload: {}
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Ошибка',
        text: 'Не удалось удалить группу. Повторите попытку позже.',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_DELETE_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_DELETE_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: parkActionTypes.PARK_DELETE_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    throw err
  }
}

function* createPaymentSettings(action) {
  const { data } = action.payload

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/payment-settings`,
      method: 'POST',
      data
    })

    yield put({
      type: parkActionTypes.PARK_CREATE_PAYMENT_SETTINGS_FULFILLED,
      payload: response.data
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Ошибка',
        text: 'Не удалось создать группу, повторите попытку позже.',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_CREATE_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: parkActionTypes.PARK_CREATE_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_CREATE_PAYMENT_SETTINGS_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* fetchPaymentSettingsDrivers(action) {
  const { payment_settings_id, cursor, limit } = action.payload

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/payment-settings/${payment_settings_id}/drivers`,
      method: 'GET',
      params: {
        cursor,
        limit
      }
    })

    yield put({
      type: parkActionTypes.PARK_FETCH_PAYMENT_SETTINGS_DRIVERS_FULFILLED,
      payload: {
        paymentSettingsDrivers: response.data
      }
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_FETCH_PAYMENT_SETTINGS_DRIVERS_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: parkActionTypes.PARK_FETCH_PAYMENT_SETTINGS_DRIVERS_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* createReport(action) {
  const { aggregators, date_end, date_start, report_type } = action.payload

  try {
    let req
    if(report_type === 'revise') {
      req = call(network.appAxios, {
        url: `/erp/1/park/revise`,
        method: 'POST',
        data: {
          date_end,
          date_start,
        }
      })
    } else {
      req = call(network.appAxios, {
        url: `/erp/1/report`,
        method: 'POST',
        data: {
          report_type,
          aggregators,
          date_end,
          date_start,
        }
      })
    }
    yield req

    yield put({
      type: parkActionTypes.PARK_CREATE_REPORT_FULFILLED,
      payload: {}
    })

    toast.add({
      title: 'Отчет отправлен',
      text: report_type === 'revise' ? 'Отчет для сверки платежей отправлен на почту' : 'Отчет для 1С отправлен на почту',
      color: 'success',
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Ошибка',
        text: 'Отчёт не сформирован',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_CREATE_REPORT_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "PermissionDenied") {
      yield put({
        type: parkActionTypes.PARK_CREATE_REPORT_FAIL,
        payload: {},
      })
      return
    }

    if (err.response && err.response.status === 409) {
      yield put({
        type: parkActionTypes.PARK_CREATE_REPORT_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 409 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_CREATE_REPORT_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: parkActionTypes.PARK_CREATE_REPORT_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    throw err
  }
}

function* fetchNotificationsSettings(action) {
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/notifications`,
      method: 'GET',
    })

    yield put({
      type: parkActionTypes.PARK_FETCH_NOTIFICATIONS_SETTINGS_FULFILLED,
      payload: {
        notificationsSettings: response.data
      }
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_FETCH_NOTIFICATIONS_SETTINGS_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_FETCH_NOTIFICATIONS_SETTINGS_FAIL,
        payload: { accessDenied: true },
      })
      return
    }

    throw err
  }
}


function* updateNotificationsSettings(action) {
  const {
    failed_payment_notify,
    successful_payment_notify,
    upcoming_payment_notify,
    contact_email,
    transactions_notify,
    insufficient_fuel_deposit,
    contact_phone,
    bank_balance_settings
  } = action.payload

  let data = {
    park_subscription_settings: {
      failed_payment_notify,
      successful_payment_notify,
      upcoming_payment_notify
    },
    contact_email,
    contact_phone,
    transactions_notify,
    insufficient_fuel_deposit
  };

  if (bank_balance_settings) {
    data = {
      ...data,
      bank_balance_settings
    }
  }

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/notifications`,
      method: 'PATCH',
      data
    })

    yield put({
      type: parkActionTypes.PARK_UPDATE_NOTIFICATIONS_SETTINGS_FULFILLED,
      payload: {}
    })

    toast.add({
      title: 'Изменения сохранены',
      text: 'Ваши настройки уведомлений успешно обновлены',
      color: 'success',
    })

  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось обновить данные',
        text: 'Сбой операции. Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_NOTIFICATIONS_SETTINGS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_NOTIFICATIONS_SETTINGS_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_UPDATE_NOTIFICATIONS_SETTINGS_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* fetchNotificationsSettingsTelegram(action) {
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/notifications/telegram/bot`,
      method: 'GET',
    })
    yield put({
      type: parkActionTypes.PARK_FETCH_NOTIFICATIONS_SETTINGS_TELEGRAM_FULFILLED,
      payload: {
        notificationsSettingsTelegram: response.data
      }
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_FETCH_NOTIFICATIONS_SETTINGS_TELEGRAM_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* setNotificationsSettingsTelegram(action) {
  const { secret } = action.payload
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/notifications/telegram/bot`,
      method: 'POST',
      data: {
        secret
      }
    })
    yield put({
      type: parkActionTypes.PARK_SET_NOTIFICATIONS_SETTINGS_TELEGRAM_FULFILLED,
      payload: {}
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось добавить ключ',
        text: 'Сбой операции. Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_SET_NOTIFICATIONS_SETTINGS_TELEGRAM_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

     if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: parkActionTypes.PARK_SET_NOTIFICATIONS_SETTINGS_TELEGRAM_FAIL,
        payload: err.response.data,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_SET_NOTIFICATIONS_SETTINGS_TELEGRAM_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* deleteNotificationsSettingsTelegram(action) {
  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/notifications/telegram/bot`,
      method: 'DELETE',
    })
    yield put({
      type: parkActionTypes.PARK_DELETE_NOTIFICATIONS_SETTINGS_TELEGRAM_FULFILLED,
      payload: {}
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_DELETE_NOTIFICATIONS_SETTINGS_TELEGRAM_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_DELETE_NOTIFICATIONS_SETTINGS_TELEGRAM_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* fetchPromo (action){
  const { bankType, parkID, subscriptionStatus } = action.payload

  try {
    const response = yield call(network.appAxios, {
      url: `/tracker/erp?bank_type=${bankType}&park_id=${parkID}&subscription_status=${subscriptionStatus}`,
      method: 'GET',
      baseURL: process.env.REACT_APP_TRACKER_URL
    })

    const data = response.data

    yield put({
      type: parkActionTypes.PARK_FETCH_PROMO_FULFILLED,
      payload: {
        promo: {
          message: data.message,
          textColor: data.text_color,
          backgroundColor: data.background_color,
          url: data.url
        }
      },
    })

  } catch (err) {
    yield put({
      type: parkActionTypes.PARK_FETCH_PROMO_FAIL,
      payload: err,
    })
    if (err.response) {
      throw err
    }
  }
}



function* withdrawToDeposit(action) {
  const {payload} = action

  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/park/withdraw-to-deposit`,
      method: 'POST',
      data: {
        "amount": {
          "currency": "RUR",
          "amount": payload.amount
        }
      }
    })

    yield put({
      type: parkActionTypes.PARK_WITHDRAW_TO_DEPOSIT_FULFILLED,
      payload: {},
    })

    toast.add({
      title: 'Баланс успешно пополнен',
      text: 'Средства будут зачислены в течении 15 минут',
      color: 'primary',
    })

  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось пополнить баланс',
        text: 'Сбой операции. Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_WITHDRAW_TO_DEPOSIT_FAIL,
        payload: err.response.data.payload,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: parkActionTypes.PARK_WITHDRAW_TO_DEPOSIT_FAIL,
        payload: err.response.data.payload,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_WITHDRAW_TO_DEPOSIT_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

function* notifyBot(action) {
  const {payload} = action

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/park/notify-bot`,
      method: 'POST',
      data: payload
    });

    yield put({
      type: parkActionTypes.PARK_NOTIFY_BOT_FULFILLED,
      payload: {},
    })
  } catch (err) {

    yield put({
      type: parkActionTypes.PARK_NOTIFY_BOT_FAIL,
      payload: null
    })
    return

    throw err
  }
}


function* setFuelEnabled(action) {
  const {fuel_enabled} = action.payload

  try {
    yield call(network.appAxios, {
      url: `/erp/2/fuel/park/enabled`,
      method: 'PATCH',
      data: {
        fuel_enabled: fuel_enabled,
      }
    })
    yield put({
      type: parkActionTypes.PARK_FUEL_ENABLED_FULFILLED,
      payload: fuel_enabled
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: parkActionTypes.PARK_FUEL_ENABLED_FAIL,
        payload: err.response.data,
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: parkActionTypes.PARK_FUEL_ENABLED_FAIL,
        payload: err.response.data,
      })
      return
    }

    throw err
  }
}

export default function* parkSagas() {
  yield all([
    yield takeLatest(parkActionTypes.PARK_DETAILS_REQUEST, fetchDetails),
    yield takeLatest(
      parkActionTypes.PARK_CREDS_REQUEST,
      fetchParkCreds,
    ),
    yield takeLatest(parkActionTypes.PARK_CREDS_RESET_REQUEST, fetchParkCredsReset),
    yield takeLatest(parkActionTypes.PARK_TECHNICAL_SUPPORT_REQUEST, fetchTechnicalSupport),
    yield takeLatest(parkActionTypes.PARK_UPDATE_TECHNICAL_SUPPORT_REQUEST, updateTechnicalSupport),
    yield takeLatest(parkActionTypes.PARK_FETCH_CREDENTIALS_REQUEST, fetchCredentials),
    yield takeLatest(parkActionTypes.PARK_UPDATE_CREDENTIALS_REQUEST, updateCredentials),
    yield takeLatest(parkActionTypes.PARK_FETCH_ALFA_CERTIFICATE_REQUEST, fetchAlfaCertificate),
    yield takeLatest(parkActionTypes.PARK_UPDATE_ALFA_CERTIFICATE_REQUEST, updateAlfaCertificate),
    yield takeLatest(parkActionTypes.PARK_FETCH_TINKOFF_KEY_REQUEST, fetchTinkoffKey),
    yield takeLatest(parkActionTypes.PARK_UPDATE_TINKOFF_KEY_REQUEST, updateTinkoffKey),
    yield takeLatest(parkActionTypes.PARK_FETCH_AGGREGATORS_REQUEST, fetchAggregators),
    yield takeLatest(parkActionTypes.PARK_FETCH_AGGREGATOR_REQUEST, fetchAggregator),
    yield takeLatest(parkActionTypes.PARK_DELETE_AGGREGATOR_REQUEST, deleteAggregator),
    yield takeLatest(parkActionTypes.PARK_UPDATE_AGGREGATOR_REQUEST, updateAggregator),
    yield takeLatest(parkActionTypes.PARK_CREATE_AGGREGATOR_REQUEST, createAggregator),
    yield takeLatest(parkActionTypes.PARK_CREATE_PAYMENT_ORDER_REQUEST, createPaymentOrder),
    yield takeLatest(parkActionTypes.PARK_FETCH_EDGVR_API_KEY_REQUEST, fetchEdgvrApiKey),
    yield takeLatest(parkActionTypes.PARK_UPDATE_EDGVR_API_KEY_REQUEST, updateEdgvrApiKey),
    yield takeLatest(parkActionTypes.PARK_DELETE_EDGVR_API_KEY_REQUEST, deleteEdgvrApiKey),
    yield takeLatest(parkActionTypes.PARK_FETCH_ALL_PAYMENT_SETTINGS_REQUEST, fetchAllPaymentSettings),
    yield takeLatest(parkActionTypes.PARK_ASSIGN_PAYMENT_SETTINGS_REQUEST, assignPaymentSettings),
    yield takeLatest(parkActionTypes.PARK_FETCH_NOTIFICATIONS_REQUEST, fetchNotifications),
    yield takeLatest(parkActionTypes.PARK_CREATE_MASS_NOTIFICATION_REQUEST, createMassNotification),
    yield takeLatest(parkActionTypes.PARK_UPDATE_PAYMENT_SETTINGS_REQUEST, updatePaymentSettings),
    yield takeLatest(parkActionTypes.PARK_DELETE_PAYMENT_SETTINGS_REQUEST, deletePaymentSettings),
    yield takeLatest(parkActionTypes.PARK_CREATE_PAYMENT_SETTINGS_REQUEST, createPaymentSettings),
    yield takeLatest(parkActionTypes.PARK_FETCH_PAYMENT_SETTINGS_DRIVERS_REQUEST, fetchPaymentSettingsDrivers),
    yield takeLatest(parkActionTypes.PARK_CREATE_REPORT_REQUEST, createReport),
    yield takeLatest(parkActionTypes.PARK_FETCH_NOTIFICATIONS_SETTINGS_REQUEST, fetchNotificationsSettings),
    yield takeLatest(parkActionTypes.PARK_FETCH_NOTIFICATIONS_SETTINGS_TELEGRAM_REQUEST, fetchNotificationsSettingsTelegram),
    yield takeLatest(parkActionTypes.PARK_UPDATE_NOTIFICATIONS_SETTINGS_REQUEST, updateNotificationsSettings),
    yield takeLatest(parkActionTypes.PARK_SET_NOTIFICATIONS_SETTINGS_TELEGRAM_REQUEST, setNotificationsSettingsTelegram),
    yield takeLatest(parkActionTypes.PARK_DELETE_NOTIFICATIONS_SETTINGS_TELEGRAM_REQUEST, deleteNotificationsSettingsTelegram),
    yield takeLatest(parkActionTypes.PARK_FETCH_PROMO_REQUEST, fetchPromo),
    yield takeLatest(
      parkActionTypes.PARK_WITHDRAW_TO_DEPOSIT_REQUEST,
      withdrawToDeposit
    ),
    yield takeLatest(parkActionTypes.PARK_NOTIFY_BOT_REQUEST, notifyBot),
    yield takeLatest(parkActionTypes.PARK_FUEL_ENABLED_REQUEST, setFuelEnabled),
  ])
}
