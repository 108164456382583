import {all, call, put, select, take} from 'redux-saga/effects'
import userActionTypes from './userActionTypes'
import {takeLatest} from '@redux-saga/core/effects'
import {axiosUpdate, network, updateToken} from "../network";
import {history} from "../store";
import parkActionTypes from "../park/parkActionTypes";
import fuelActionTypes from '../fuel/fuelActionTypes';
import appActionTypes from '../app/appActionTypes';
import { toast } from 'utils/toast';
import { push } from "connected-react-router";
import { transactionsActions } from "store/transactions/transactionsActions";

function* authorization(action) {
  const {payload} = action
  try {
    const response = yield call(network.appAxios, {
      url: `/auth/1/login/email`,
      method: 'POST',
      data: {
        email: payload.email,
        password: payload.password,
      }
    })
    const {access, refresh} = response.data

    axiosUpdate(access, refresh)

    yield put({
      type: userActionTypes.USER_AUTHORIZATION_FULFILLED,
      payload: {
        accessToken: access,
        refreshToken: refresh,
      },
    })

    yield put({
      type: userActionTypes.USER_AUTHENTICATION_REQUEST,
      payload: {},
    })

  } catch (err) {
    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      const errors = {email: 'Неверные авторизационные данные.', password: 'Неверные авторизационные данные.'}
      yield put({
        type: userActionTypes.USER_AUTHORIZATION_FAIL,
        payload: {errors},
      })
      return errors
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "IncorrectCredentials") {
      const errors = {email: 'Неверные авторизационные данные.', password: 'Неверные авторизационные данные.'}
      yield put({
        type: userActionTypes.USER_AUTHORIZATION_FAIL,
        payload: {errors},
      })
      return errors
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ValidationError") {
      const errors = {email: 'Неверные авторизационные данные.', password: 'Неверные авторизационные данные.'}
      yield put({
        type: userActionTypes.USER_AUTHORIZATION_FAIL,
        payload: {errors},
      })
      return errors
    }

    throw err
  }
}

function* authentication() {
  const state = yield select();

  const {accessToken, refreshToken} = state.user

  if (!accessToken || !refreshToken) {
    yield put({
      type: userActionTypes.USER_AUTHENTICATION_FAIL,
      payload: {},
    })
    return
  }

  try {
    const response = yield call(network.appAxios, {
      url: `/erp/1/employee/profile`,
      method: 'GET',
    })
    const profile = response.data

    yield put({
      type: userActionTypes.USER_AUTHENTICATION_FULFILLED,
      payload: {
        profile
      },
    })

    yield put({
      type: parkActionTypes.PARK_DETAILS_REQUEST,
      payload: {},
    });
    yield put({
      type: parkActionTypes.PARK_CREDS_REQUEST,
      payload: {},
    });
    yield put(transactionsActions.fetchCounters());
    yield put({
      type: fuelActionTypes.FUEL_SUMMARY_REQUEST,
      payload: {},
    })

    yield take([
      parkActionTypes.PARK_DETAILS_FULFILLED,
    ])
    yield take([
      fuelActionTypes.FUEL_SUMMARY_FULFILLED,
    ])
  } catch (err) {
    if (err.response && err.response.status === 500) {
      yield put({
        type: userActionTypes.USER_AUTHENTICATION_FAIL,
        payload: {},
      })

      toast.add({
        title: 'Ошибка',
        text: 'Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: userActionTypes.USER_AUTHENTICATION_FAIL,
        payload: {},
      })
      yield put({
        type: userActionTypes.USER_LOGOUT,
        payload: {},
      })
      toast.add({
        title: 'Ошибка',
        text: 'Доступ запрещен.',
        color: 'danger',
      })
      return
    }

    if (err.response && err.response.status === 401 && err.response.data.code === "Unauthorized") {
      yield put({
        type: userActionTypes.USER_AUTHENTICATION_FAIL,
        payload: {},
      })
      yield put({
        type: userActionTypes.USER_LOGOUT,
        payload: {},
      })
      toast.add({
        title: 'Ошибка',
        text: 'Ошибка авторизации. Для продолжения работы авторизуйтесь повторно.',
        color: 'danger',
      })
      return
    }

    if (err.response && err.response.status === 409 && err.response.data.code === "OrganizationDetailsAreEmpty") {
      yield put({
        type: userActionTypes.USER_AUTHENTICATION_FULFILLED,
        payload: {
          profile: null
        },
      });

      return
    }

    if (err.response && err.response.status === 409 && err.response.data.code === "ParkNotConfirmed") {
      yield put(push('/email/verification/success'));

      yield put({
        type: userActionTypes.USER_AUTHENTICATION_FAIL,
        payload: {},
      });

      clearTimeout(network.tokenUpdateTimeout)
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')

      return;
    }

    throw err
  }
}

function* restore(action) {
  const {payload} = action
  console.log(payload);

  if (payload.accessToken && payload.refreshToken) {
    axiosUpdate(payload.accessToken, payload.refreshToken);
  }

  yield put({
    type: userActionTypes.USER_AUTHENTICATION_REQUEST,
    payload: {},
  })
}

function* registerPark(action) {
  const {payload} = action

  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/park/register`,
      method: 'POST',
      data: {
        email: payload.email,
        password: payload.password,
        phone: payload.phone,
        ogrn: payload.ogrn,
        is_legal: true
      }
    });

    yield put({
      type: userActionTypes.USER_REGISTER_PARK_FULFILLED,
      payload: {

      },
    })

  } catch (err) {

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: userActionTypes.USER_REGISTER_PARK_FAIL,
        payload: {},
      })

      toast.add({
        title: 'Ошибка',
        text: 'Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
      return
    }

    if (err.response && err.response.status === 409 && err.response.data.code === 'ParkRegisterOrderIsAlreadyExist') {
      const errors = {email: 'Заявка на регистрацию парка уже создана.', ogrn: 'Заявка на регистрацию парка уже создана'}

      yield put({
        type: userActionTypes.USER_REGISTER_PARK_FAIL,
        payload: errors,
      })
      return errors
    }

    if (err.response && err.response.status === 409 && err.response.data.code === 'InvalidParkData') {
      const errors = {ogrn: 'Неверные данные ОГРН'}

      yield put({
        type: userActionTypes.USER_REGISTER_PARK_FAIL,
        payload: errors,
      })
      return errors
    }

    if (err.response && err.response.status === 409 && ['UserAlreadyConfirmed', 'UserAlreadyExist', 'ParkIsAlreadyExistsButUserNotFound'].includes(err.response.data.code)) {
      const errors = {
        email: err.response.data.code === "ParkIsAlreadyExistsButUserNotFound" ?
          "На данный e-mail уже отправлено письмо с подтверждением регистрации личного кабинета, проверьте почтовый ящик, адрес которого указан в форме регистрации"
          : err.response.data.message,
      };

      yield put({
        type: userActionTypes.USER_REGISTER_PARK_FAIL,
        payload: errors,
      })
      return errors
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: userActionTypes.USER_REGISTER_PARK_FAIL,
        payload: err.response.data
      })
      return
    }


    throw err
  }

}

function* confirmEmail(action) {
  const {payload} = action

  const alertError = () => {
    toast.add({
      title: 'Ошибка',
      text: 'Доступен запрещен',
      color: 'danger',
    })
  }

  try {
    if (payload) {
      const response = yield call(network.appAxios, {
        url: `auth/1/signup/email/confirm`,
        method: 'POST',
        data: {
          confirmCode: payload
        }
      });

      yield put({
        type: userActionTypes.USER_CONFIRM_EMAIL_FULFILLED,
        payload: {
          data: response.data
        },
      })
    } else {
      yield put({
        type: userActionTypes.USER_CONFIRM_EMAIL_FAIL,
        payload: {},
      })

      yield put({
        type: userActionTypes.USER_LOGOUT,
        payload: {},
      })

     alertError();
    }

  } catch (err) {

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: userActionTypes.USER_CONFIRM_EMAIL_FAIL,
        payload: {},
      })

      yield put({
        type: userActionTypes.USER_LOGOUT,
        payload: {},
      })

      alertError();

      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: userActionTypes.USER_CONFIRM_EMAIL_FAIL,
        payload: {},
      })

      yield put({
        type: userActionTypes.USER_LOGOUT,
        payload: {},
      })

      alertError();

      return;
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ValidationError") {
      yield put({
        type: userActionTypes.USER_CONFIRM_EMAIL_FAIL,
        payload: {},
      })

      yield put({
        type: userActionTypes.USER_LOGOUT,
        payload: {},
      })

      alertError();

      return
    }

    throw err
  }
}

function* resendEmailConfirmation(action) {
    const {payload} = action

    try {
        const response = yield call(network.appAxios, {
          url: `auth/1/signup/email/retry`,
          method: 'POST',
          data: {
            email: payload
          }
        });

        yield put({
          type: userActionTypes.USER_RESEND_EMAIL_CONFIRMATION_FULFILLED,
          payload: {
            email: payload
          },
        })
    } catch (err) {

      if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
        yield put({
          type: userActionTypes.USER_RESEND_EMAIL_CONFIRMATION_FAIL,
          payload: {},
        })


        return
      }

      if (err.response && err.response.status === 500 && err.response.data.code === "ValidationError") {
        yield put({
          type: userActionTypes.USER_RESEND_EMAIL_CONFIRMATION_FAIL,
          payload: {},
        })

        return;
      }

      throw err
    }

}

function* logout() {
  clearTimeout(network.tokenUpdateTimeout)
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  history.push('/');
}

function* setPassword(action) {
  const {payload} = action
  const {email, oldPassword, newPassword} = payload

  try {
    const response = yield call(network.appAxios, {
      url: `/auth/1/common/password`,
      method: 'POST',
      data: {
        email: payload.email,
        oldPassword: payload.oldPassword,
        newPassword: payload.newPassword,
      }
    })
    // todo: update tokens?
    // const {access, refresh} = response.data
    yield put({
      type: userActionTypes.USER_SET_PASSWORD_FULFILLED,
      payload: {},
    })
  } catch (err) {
    const alertError = () => {
      toast.add({
        title: 'Не удалось изменить пароль',
        text: 'Пожалуйста, повторите попытку позже',
        color: 'danger',
      })
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: userActionTypes.USER_SET_PASSWORD_FAIL,
        payload: err.response.data.payload,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ValidationError") {
      yield put({
        type: userActionTypes.USER_SET_PASSWORD_FAIL,
        payload: err.response.data.payload,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: userActionTypes.USER_SET_PASSWORD_FAIL,
        payload: err.response.data.payload,
      })
      alertError()
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: userActionTypes.USER_SET_PASSWORD_FAIL,
        payload: err.response.data.payload,
      })
      return
    }

    throw err
  }
}


function* resetPassword(action) {
  const {email} = action.payload

  try {
    const response = yield call(network.appAxios, {
      url: `/auth/1/password/reset`,
      method: 'POST',
      data: {email}
    })
    yield put({
      type: userActionTypes.USER_RESET_PASSWORD_FULFILLED,
      payload: {},
    })
    toast.add({
      title: 'Готово',
      text: 'Ссылка для изменения пароля была отправлена на email',
      color: 'success',
    })
  } catch (err) {
    if (err.response && err.response.status === 500 && err.response.data.code === "ServerError") {
      yield put({
        type: userActionTypes.USER_RESET_PASSWORD_FAIL,
        payload: {email: 'При обработке запроса произошла ошибка'},
      })
      return
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ValidationError") {
      yield put({
        type: userActionTypes.USER_RESET_PASSWORD_FAIL,
        payload: {email: 'При обработке запроса произошла ошибка'},
      })
      return
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      yield put({
        type: userActionTypes.USER_RESET_PASSWORD_FAIL,
        payload: {email: 'Email не зарегистрирован в системе'},
      })
      return
    }

    throw err
  }
}

function* resetPasswordConfirm(action) {
  const {payload} = action

  try {
      const response = yield call(network.appAxios, {
        url: `auth/1/password/reset/confirm`,
        method: 'POST',
        data: {
          newPassword: payload.newPassword,
          confirmCode: payload.code,
          email: payload.email
        }
      });

      yield put({
        type: userActionTypes.USER_RESET_PASSWORD_CONFIRM_FULFILLED,
        payload: {
          data: response.data
        },
      })
  } catch (err) {

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: userActionTypes.USER_RESET_PASSWORD_CONFIRM_FAIL,
        payload: err.response.data.payload ? err.response.data.payload : []
      })

      return err.response.data.payload ? err.response.data.payload : [];
    }

    if (err.response && err.response.status === 403 && err.response.data.code === "Forbidden") {
      const errors = { password: 'Неверная ссылка восстановления пароля.' }

      yield put({
        type: userActionTypes.USER_RESET_PASSWORD_CONFIRM_FAIL,
        payload: errors,
      })

      return errors;
    }

    throw err
  }
}


function* sendPhoneConfirmOTP(action) {
  const {payload} = action
  console.log(payload);


  const refreshToken = localStorage.getItem('refreshToken')

  try {
    const response = yield call(network.appAxios, {
      url: `auth/1/phone-confirm/OTP/confirm`,
      method: 'POST',
      headers:  {
        'x-app-id': process.env.REACT_APP_X_APP_ID,
      },
      data: {
        attemptId: payload.attemptId,
        code: payload.code,
      }
    });

    yield put({
      type: userActionTypes.USER_SEND_PHONE_CONFIRM_OTP_FULFILLED,
      payload: {
        data: response.data
      },
    })
    updateToken(refreshToken)
  } catch (err) {

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: userActionTypes.USER_SEND_PHONE_CONFIRM_OTP_FAIL,
        payload: err.response.data.payload ? err.response.data.payload : []
      })

      return err.response.data.payload ? err.response.data.payload : [];
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ValidationError") {
      yield put({
        type: userActionTypes.USER_SEND_PHONE_CONFIRM_OTP_FAIL,
        payload: err.response.data.payload ? err.response.data.payload : []
      })

      return err.response.data.payload ? err.response.data.payload : [];
    }

    if (err.response && err.response.status === 520 && err.response.data.code === "ServerError") {
      yield put({
        type: userActionTypes.USER_SEND_PHONE_CONFIRM_OTP_FAIL,
        payload: err.response.data.payload ? err.response.data.payload : []
      })

      return err.response.data.payload ? err.response.data.payload : [];
    }

    if (err.response && err.response.status === 403) {
      yield put({
        type: userActionTypes.USER_SEND_PHONE_CONFIRM_OTP_FAIL,
        payload: {error: 'Некорректный код ОТР'}
      })

      return err.response.data.payload ? err.response.data.payload : [];
    }
    if (err.response && err.response.status === 401) {
      yield put({
        type: userActionTypes.USER_SEND_PHONE_CONFIRM_OTP_FAIL,
        payload: {error: 'Некорректный код ОТР'}
      })

      updateToken(refreshToken);
      sendPhoneConfirmOTP(action)

      return err.response.data.payload ? err.response.data.payload : [];
    }

    throw err
  }
}


function* sendPhoneOTP(action) {
  const {payload} = action


  try {
    const response = yield call(network.appAxios, {
      url: `auth/1/phone-confirm/OTP`,
      method: 'POST',
      headers:  {
        'x-app-id': process.env.REACT_APP_X_APP_ID
      },
      data: {
        phone: payload,
      }
    });

    yield put({
      type: userActionTypes.USER_SEND_PHONE_OTP_FULFILLED,
      payload: {
        data: response.data
      },
    })
  } catch (err) {
    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: userActionTypes.USER_RESET_PASSWORD_CONFIRM_FAIL,
        payload: err.response.data.payload ? err.response.data.payload : []
      })

      return err.response.data.payload ? err.response.data.payload : [];
    }

    if (err.response && err.response.status === 500 && err.response.data.code === "ValidationError") {
      yield put({
        type: userActionTypes.USER_RESET_PASSWORD_CONFIRM_FAIL,
        payload: {email: 'При обработке запроса произошла ошибка'},
      })
      return
    }


    throw err
  }
}

function* updateRequisites(action) {
  const {payload} = action

  try {
    const detailsResponse = yield call(network.appAxios, {
      url: `erp/1/park/organization-details`,
      method: 'PATCH',
      data: {
        bank_type: payload.bank_type,
        legal_name: payload.company,
        bik: payload.bik,
        bank_account: payload.bank_account
      }
    });

    yield put({
      type: userActionTypes.USER_UPDATE_REQUISITES_FULFILLED,
      payload: {},
    });
  } catch (err) {

    if (err.response && err.response.status === 409 && err.response.data.code === "OrganizationDetailsAlreadyFilled") {
      toast.add({
        title: 'Ошибка',
        text: err.response.data.message,
        color: 'danger',
      });

      yield put({
        type: userActionTypes.USER_UPDATE_REQUISITES_FULFILLED,
        payload: {},
      });

      return;
    }

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: userActionTypes.USER_UPDATE_REQUISITES_FAIL,
        payload: {
          errors: err.response.data.payload ? err.response.data.payload : []
        },
      });


      return err.response.data.payload ? err.response.data.payload : [];
    }

    throw err;
  }

  try {
    const contactsResponse = yield call(network.appAxios, {
      url: `erp/1/park/technical-support-contacts`,
      method: 'PATCH',
      data: {
        email: payload.email,
        phone: payload.phone
      }
    });

    yield put({
      type: userActionTypes.USER_UPDATE_REQUISITES_FULFILLED,
      payload: {},
    });
  } catch (err) {

    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: userActionTypes.USER_UPDATE_REQUISITES_FAIL,
        payload: {
          errors: err.response.data.payload ? err.response.data.payload : []
        },
      });


      return err.response.data.payload ? err.response.data.payload : [];
    }

    throw err
  }
}

function* updateFullName(action) {
  const {payload} = action

  try {
    const response = yield call(network.appAxios, {
      url: `erp/1/employee/profile`,
      method: 'POST',
      data: {
        full_name: payload.full_name,
        position: payload.position
      }
    });

    yield put({
      type: userActionTypes.USER_UPDATE_FULL_NAME_FULFILLED,
      payload: {},
    });

    yield put({
      type: appActionTypes.APP_INIT,
      payload: {},
    });

    yield put({
      type: userActionTypes.USER_AUTHENTICATION_REQUEST,
      payload: {},
    });
  } catch (err) {
    if (err.response && err.response.status === 400 && err.response.data.code === "InputValidationError") {
      yield put({
        type: userActionTypes.USER_UPDATE_FULL_NAME_FAIL,
        payload: {
          errors: err.response.data.payload ? err.response.data.payload : []
        },
      });


      return err.response.data.payload ? err.response.data.payload : [];
    }

    throw err;
  }
}



export default function* userSagas() {
  yield all([
    yield takeLatest(userActionTypes.USER_REGISTER_PARK_REQUEST,  registerPark),
    yield takeLatest(userActionTypes.USER_CONFIRM_EMAIL_REQUEST,  confirmEmail),
    yield takeLatest(userActionTypes.USER_RESEND_EMAIL_CONFIRMATION_REQUEST, resendEmailConfirmation),
    yield takeLatest(userActionTypes.USER_AUTHORIZATION_REQUEST, authorization),
    yield takeLatest(userActionTypes.USER_AUTHENTICATION_REQUEST, authentication),
    yield takeLatest(userActionTypes.USER_SET_PASSWORD_REQUEST, setPassword),
    yield takeLatest(userActionTypes.USER_RESTORE, restore),
    yield takeLatest(userActionTypes.USER_LOGOUT, logout),
    yield takeLatest(userActionTypes.USER_RESET_PASSWORD_REQUEST, resetPassword),
    yield takeLatest(userActionTypes.USER_RESET_PASSWORD_CONFIRM_REQUEST, resetPasswordConfirm),
    yield takeLatest(userActionTypes.USER_UPDATE_REQUISITES_REQUEST, updateRequisites),
    yield takeLatest(userActionTypes.USER_UPDATE_FULL_NAME_REQUEST, updateFullName),
    yield takeLatest(userActionTypes.USER_SEND_PHONE_OTP_REQUEST, sendPhoneOTP),
    yield takeLatest(userActionTypes.USER_SEND_PHONE_CONFIRM_OTP_REQUEST, sendPhoneConfirmOTP),
  ])
}
